@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.fp-watermark {
  display: none;
}

* {
  -webkit-user-drag: none;
}

.fp-controlArrow {
  opacity: 0.1;
  transition: all 0.4s linear;
}


.fp-controlArrow:hover {
  opacity: 0.4 !important;
  transition: all 0.1s linear;
}
